import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { AlertService, ApiService, AuthenticationService, DataService } from "../_services";
import { DocumentModel } from "./document.model";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver/dist/FileSaver";
import { getRequestDataModel } from "./requestDataModel";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-select-industry-change-report",
  templateUrl: "./industryChangeReport.component.html",
  styleUrls: ["./document.component.scss"],
})
export class IndustryChangeReportComponent implements OnInit {
  formModel: DocumentModel;
  formEnabled: boolean;
  params: any;
  selectedTab: String;
  comments: string;
  loggedInUserName: string;
  checkInDocument: any;
  checkInDocumentPath: string;
  industryIssuers: any;
  epsOrEbitda = ['EPS', 'EBITDA'];
  yearList = [2016,2017,2018,2019,2020,2021,2022,2023,2024,2025,2026,2027,2028];
  units =['M', 'B', '000']
  modalRef: any;
  tableHeaders: any;
  closeResult: any;
  showErrorOnClick = false;
  issuerSaveDisable = false;
  constructor(
    private authService: AuthenticationService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef
  ) {
    this.formModel = new DocumentModel();
    this.formEnabled = false;
    this.route.params.subscribe((params) => (this.params = params));
    this.checkInDocumentPath = "Choose file";
    this.selectedTab = "Metadata";
  }
  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.cdr.detectChanges()
  }
  ngOnInit(): void {
    this.authService.user.subscribe((user) => (this.loggedInUserName = user.displayName));
    if (this.params.action === "create") {
      this.apiService.createDocument({ productType: "Industry With Changes Report" }).subscribe((data) => (this.formModel.actionNote.productID = Number(data)));
    } else {
      if (!this.dataService.selectedStage || !this.dataService.openDocumentData) {
        this.router.navigate(["/documents"]);
      } else {
        const requestBody = {
          productType: this.dataService.openDocumentData.productName,
          versionID: 1,
          stageName: this.dataService.selectedStage,
          productID: this.dataService.openDocumentData.productID,
        };
        this.apiService.openDocument(requestBody).subscribe((data) => {
          this.setInitialData(data);
        });
      }
    }
    this.apiService.getPrimaryIndustries().subscribe((data) => (this.formModel.primaryIndustries = data));
    this.apiService.getAnalysts().subscribe((data) => (this.formModel.primaryAuthors = data));
    this.apiService.getSecondaryIssuers().subscribe((data) => (this.formModel.secondaryIssuers = data));
    this.formModel.industryRecommendations = ['Buy', 'Hold', 'Sell','Speculative Buy',
    'Speculative Hold',
    'Speculative Sell',
    'Suspending Coverage'];
  }
  
  

  enableTab(tab): boolean {
    return !(tab === "distribution" && this.dataService.selectedStage === "Production");
  }

  createDocument(event): void {
    const requestBody = {
      productID: this.formModel.actionNote.productID,
      versionID: 1,
      industry: event,
      stageName: "",
    };
    this.apiService.createSpecifiedDocument("industrychangereport", requestBody).subscribe((data) => {
      this.setInitialData(data);
    });
  }

  setInitialData(data) {
    if(data.actionNote.productIndustryDetails.startYear === 0){
      data.actionNote.productIndustryDetails.startYear = null
    }
    if(data.actionNote.productIndustryDetails.eps_EBITA === ''){
      data.actionNote.productIndustryDetails.eps_EBITA = null
    }
    if(data.actionNote.productIndustryDetails.unit === ''){
      data.actionNote.productIndustryDetails.unit = null
    }
    this.formModel.actionNote = data.actionNote;
    this.formModel.selectedPrimaryIndustry = this.formModel.actionNote.metadata.primaryIndustry[0].name;
    this.formEnabled = true;
    this.formModel.actionNote.distributionRecipient.forEach((value) => (value.prevSelected = value.selected));
  }

  saveOnTabChange(tab) {
    if (!this.formModel.actionNote.readOnly) {
      switch (this.selectedTab) {
        case "Metadata":
          this.onSave();
          break;
        case "FinancialMatrix":
          break;
        case "Comments":
          this.onAddComment();
          break;
        case "AuthorDocument":
          break;
        case "DistributionRecipients":
          this.saveDistribution();
          break;
        default:
          break;
      }
    }

    this.selectedTab = tab;
  }

  abstractHTMLChanged(event) {
    this.formModel.actionNote.metadata.abstract = event.text;
  }

  openAbstractHTML() {
    const requestBody = {
      tagName: null,
      text: this.formModel.actionNote.metadata.htmlText,
    };
    this.apiService.previewHTmlBody(requestBody).subscribe((response) => {
      const newWindow = window.open();
      newWindow.document.write(response.text);
      newWindow.document.close();
    });
  }

  onClose() {
    this.apiService
      .closeDocument({
        productType: this.formModel.actionNote.productName,
        versionID: 1,
        stageName: this.dataService.selectedStage,
        productID: this.formModel.actionNote.productID,
      })
      .subscribe((data) => this.router.navigate(["/documents"]));
  }

  onSave(isSave?) {
    if(this.formModel.actionNote.productIndustryDetails.eps_EBITA === 'EPS'){
        this.formModel.actionNote.productIndustryDetails.unit = '';
    }
    this.apiService.updateDocument("industrychangereport", getRequestDataModel(this.formModel, false)).subscribe((data) => {});
  }

  getComment(): string {
    const timeStamp = new Date();
    const previousComments = this.formModel.actionNote.comments || "";
    const currentComments = this.comments ? `<br /><br />${this.loggedInUserName} ${timeStamp.toString()}:<br />${this.comments}` : "";
    const comment = `${previousComments} ${currentComments}`;
    this.formModel.actionNote.comments = comment;
    return comment;
  }

  onAddComment() {
    this.apiService
      .saveDocumentComments("industrychangereport", {
        productID: this.formModel.actionNote.productID,
        VersionID: 1,
        Comments: this.getComment(),
      })
      .subscribe((data) => (this.comments = ""));
  }

  getBlankTemplate(): void {
    this.apiService.downloadBlankTemplate(this.formModel.actionNote.productName, this.formModel.actionNote.docID).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  checkOutDocument(): void {
    this.apiService.checkoutDocument(this.formModel.actionNote.productName, "workflow", this.formModel.actionNote.docID, this.dataService.selectedStage).subscribe((response) => {
      let fileName = response.headers.get('content-disposition').split('filename="')[1].split(';')[0].replace('"','');
      const blob = new Blob([response.body], { type: "application/msword" });
      if(fileName){
        saveAs(blob, fileName);
      }
      else{
        saveAs(blob, `${this.formModel.actionNote.docID}.docx`);
      }
    });
  }

  saveDistribution() {
    const requestBody = {
      versionID: 1,
      distributionGroup: this.formModel.actionNote.distributionGroup,
      productID: this.formModel.actionNote.productID,
      distributionRecipient: this.formModel.actionNote.distributionRecipient,
    };

    this.apiService.saveDistribution(requestBody).subscribe();
  }

  checkDistributionRecipients(data) {
    this.formModel.actionNote.distributionRecipient.forEach((value) => {
      if (value.name.includes("Limited") || value.name.includes("Unlimited")) {
        value.selected = false;
      }
      if (value.name.includes(data)) {
        value.selected = true;
      }
    });
  }

  checkDisabled(data) {
    if (data.name.includes("Limited") || data.name.includes("Unlimited")) {
      return !data.name.includes(this.formModel.actionNote.distributionGroup);
    } else {
      return !data.prevSelected;
    }
  }

  // onFileChange(event, fileInput): void {
  //   if (`${this.formModel.actionNote.docID}.docx` === fileInput.files[0].name || `${this.formModel.actionNote.docID}.doc` === fileInput.files[0].name) {
  //     this.checkInDocument = fileInput.files[0];
  //     this.checkInDocumentPath = fileInput.files[0].name;
  //   } else {
  //     this.alertService.error("Please upload a valid document");
  //   }
  //   fileInput.value = null;
  // }

  onFileChange(event, fileInput): void {
    const file = fileInput.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();
  
    const validExtensions = ['doc', 'docx'];
  
    if (validExtensions.includes(fileExtension)) {
      this.checkInDocument = file;
      this.checkInDocumentPath = fileName;
    } else {
      this.alertService.error("Please upload a valid document (.doc or .docx)");
    }
  
    fileInput.value = null;
  }
  

  checkIn(): void {
    const formData = new FormData();
    formData.append("uploadDocument", this.checkInDocument);
    formData.append("actionDestination", "workflow");
    formData.append("actionFileName", this.checkInDocumentPath);
    formData.append("productID", `${this.formModel.actionNote.productID}`);
    formData.append("loginName", this.authService.user.value.loginName);
    this.apiService.checkinDocument(formData).subscribe((response) => {
      this.checkInDocument = null;
      this.formModel.actionNote.metadata.htmlText = response.htmlText;
      this.checkInDocumentPath = "Choose file";
    });
  }
  editIssuerData(content) {
    if(this.formModel.actionNote.metadata.secondaryIssuer.length <= 0){
      // this.alertService.error('Please select atleast one secondary issuer');
      this.showErrorOnClick = true
      var that = this;
        setTimeout(function() {
          that.showErrorOnClick = false;
      }, 5000);
      return;
    }
    if(this.formModel.actionNote.productIndustryDetails.eps_EBITA  == '' || this.formModel.actionNote.productIndustryDetails.eps_EBITA == null || this.formModel.actionNote.productIndustryDetails.eps_EBITA == undefined){
        // this.alertService.error('Please select value for EPS or EBITDA');
        this.showErrorOnClick = true
      var that = this;
        setTimeout(function() {
          that.showErrorOnClick = false;
      }, 5000);
        return;
    }
    if(this.formModel.actionNote.productIndustryDetails.eps_EBITA  === 'EPS'){
        if(this.formModel.actionNote.productIndustryDetails.startYear == null || this.formModel.actionNote.productIndustryDetails.startYear == undefined || this.formModel.actionNote.productIndustryDetails.startYear === '' || this.formModel.actionNote.productIndustryDetails.startYear === 0){
          // this.alertService.error('Please select valid start year')
          this.showErrorOnClick = true
      var that = this;
        setTimeout(function() {
          that.showErrorOnClick = false;
      }, 5000);
          return;
        }
    }
    else{
      if(this.formModel.actionNote.productIndustryDetails.startYear == null || this.formModel.actionNote.productIndustryDetails.startYear == undefined || this.formModel.actionNote.productIndustryDetails.startYear === '' || this.formModel.actionNote.productIndustryDetails.startYear === 0){
        // this.alertService.error('Please select valid start year')
        this.showErrorOnClick = true
      var that = this;
        setTimeout(function() {
          that.showErrorOnClick = false;
      }, 5000);
        return;
      }
      if(this.formModel.actionNote.productIndustryDetails.unit == null || this.formModel.actionNote.productIndustryDetails.unit == undefined || this.formModel.actionNote.productIndustryDetails.unit === ''){
        // this.alertService.error('Please select unit')
        this.showErrorOnClick = true
      var that = this;
        setTimeout(function() {
          that.showErrorOnClick = false;
      }, 5000);
        return;
      }
    }
    let requestBody
    if(this.formModel.actionNote.productIndustryDetails.eps_EBITA === 'EBITDA'){
       requestBody = {
        productID : this.formModel.actionNote.productID,
        eps_ebitda : this.formModel.actionNote.productIndustryDetails.eps_EBITA.toUpperCase(),
        start_year : this.formModel.actionNote.productIndustryDetails.startYear,
        secondaryIssuer : this.formModel.actionNote.metadata.secondaryIssuer,
        unit : this.formModel.actionNote.productIndustryDetails.unit
      }
  }
    else{
      requestBody = {
        productID : this.formModel.actionNote.productID,
        eps_ebitda : this.formModel.actionNote.productIndustryDetails.eps_EBITA.toUpperCase(),
        start_year : this.formModel.actionNote.productIndustryDetails.startYear,
        secondaryIssuer : this.formModel.actionNote.metadata.secondaryIssuer
      }
    }
    
    this.apiService.fetchIssuerDetails(requestBody).subscribe(( data ) => {
       if (data.success) {
        this.industryIssuers = data.productIssuers;
        for (let i = 0; i < this.industryIssuers.length; i++) {
            this.industryIssuers[i].visible = false;
            this.industryIssuers[i].toggleIcon = 'fa-plus-circle';
                      
        }
        this.tableHeaders = data.header;
        this.onValueChange();
        this.modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', container: '#viewEditIssuers'}).result.then((result) => {         
            this.closeResult = `Closed with: ${result}`;
            this.apiService.updateIssuerDetails(this.formModel.actionNote.productID, this.industryIssuers).subscribe((res)=>{
            });
        }, (reason) => {
          this.closeResult = `Dismissed `;
          // Dismissed
        })
       }
    })
    
  }
  onValueChange(event?){
        for (let i = 0; i < this.industryIssuers.length; i++) {
          if(!this.formModel.iwcPriceValidator('',this.industryIssuers[i].targetPrice)){
            this.issuerSaveDisable = true;
            return;
          }
          else{
            this.issuerSaveDisable = false;
          }
          // if(!this.formModel.iwcPriceValidator('',this.industryIssuers[i].val2)){
          //   this.issuerSaveDisable = true;
          //   return;
          // }
          // else{
          //   this.issuerSaveDisable = false;
          // }
          // if(!this.formModel.iwcPriceValidator('',this.industryIssuers[i].val4)){
          //   this.issuerSaveDisable = true;
          //   return;
          // }
          // else{
          //   this.issuerSaveDisable = false;
          // }
          
        }
      
  }
  setVisible(event){
    event.visible = !event.visible
    event.toggleIcon = event.toggleIcon === 'fa-plus-circle' ? 'fa-minus-circle' : 'fa-plus-circle';
    for (let i = 0; i < this.industryIssuers.length; i++) {
        if (this.industryIssuers[i].issuerId != event.issuerId) {
            this.industryIssuers[i].visible = false;
            this.industryIssuers[i].toggleIcon = 'fa-plus-circle';
        }
    }
  }

  getAbstrachHTML(){
    const productID = this.formModel.actionNote.productID
    this.apiService.getAbstractHtml(productID).subscribe((res)=>{
      this.formModel.actionNote.metadata.htmlText = res;
    })
  }
 
}
